/* eslint-disable react/jsx-pascal-case */
import React, {FC, useState, useEffect} from 'react'
import {PageTitle, PageLink} from '../../../_metronic/layout/core'
// import {VendorRisk} from './VendorRisk'
import {DataRoomHeader} from './components/header/DataRoomHeader'
import {KTCard} from '../../../_metronic/helpers'
import {DataRoom} from './DataRoom'
import {set} from 'date-fns'
import {Data_room} from './Data_room'
import {useAuth} from '../../modules/auth'
import {collection, doc, onSnapshot} from 'firebase/firestore'
import db from '../../../services/firebase'
// import {PersonnelManagement} from './PersonnelManagement'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
type FileItemType = {
  id?: string
  name: string
  isFolder: boolean
  fileSize: number
  contentType: string | undefined
  timeCreated: string
  isChecked?: boolean
  tenantId?: string
  uid?: string
  additionalData?: any
  downloadUrl?: string
  folderName: string
  subFolder: string
  fName: string
  fname?: string
  is_folder:boolean
}

const DataRoomWrapper: FC = () => {
  const [item, setItem] = useState<FileItemType[]>([])
  const [value, setData] = useState(false)
  const [subFolderClicked, setSubFolderClicked] = useState(false)
  const [folderPath, setFolderPath] = useState('files/')
  const [created, setFolderFileCreated] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [totalSize, setTotalSize] = useState(0)
  const {currentUser} = useAuth()
  const[folderId,setFolderId] = useState('')

  useEffect(() => {
    // Calculate the total size
    const newTotalSize = item
      .filter(
        (item) =>
          item.is_folder !== true
      )
      .reduce((acc, item) => acc + item.fileSize, 0)
    const totalSizeInMB = (newTotalSize / 1e6).toFixed(2)
    // Update the state variable
    setTotalSize(Number(totalSizeInMB))
  }, [item])

  useEffect(() => {
    getData()
  }, [])

  // const getData = () => {
  //   try {
  //     const dataroomCollectionRef = collection(db, 'policy_files')
  //     const unsubscribe = onSnapshot(dataroomCollectionRef, (snapshot) => {
  //       const policy = snapshot.docs.map((item: any) => ({
  //         ...item.data(),
  //         id: item.id,
  //       })) as FileItemType[]
  //       setItem(policy)
  //     })

  //     // Return the unsubscribe function to stop listening for updates
  //     return unsubscribe
  //   } catch (error) {
  //     console.error('Error getting policies:', error)
  //   }
  // }

  const getData = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId!)
      const folderCollectionRef = collection(tenantRef, 'dataroom')

      const unsubscribe = onSnapshot(folderCollectionRef, (snapshot) => {
        const policies = snapshot.docs

          .map((item) => ({
            ...item.data(),
            id: item.id,
          })) as FileItemType[]

        setItem(policies)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting policies:', error)
    }
  }
  // console.log(totalSize)
  const onNewFolderClicked = () => {
    setData(true)
  }

  const updateData = () => {
    setData(!value)

    setFolderFileCreated(!created)
  }

  const onSubFolderClicked = (item) => {
  
    const tenantId = currentUser?.tenantId || ''
    console.log("folderpath",`/tenants/${tenantId}/dataroom/${item.folder_name}/`)
  setFolderPath(`/tenants/${tenantId}/dataroom/${item.folder_name}/`)
  console.log("id",item.id)
  setFolderId(item.id)
  setSubFolderClicked(false)
}


  const onFileUploaded = () => {
    setFolderFileCreated(!created)
  }
  const handleSearchTermChange = (term) => {
    setSearchTerm(term)
  }

  return (
    <>
      <PageTitle
        icon='DataRoomIcon.svg'
        title='Data Room'
        // desc={`${totalSize} MB Used of 15 GB`}
        desc='Store and share evidences for your audits'
        breadcrumbs={usersBreadcrumbs}
      >
        Data Room
      </PageTitle>
      <KTCard>
        <DataRoomHeader
          onHeaderAction={onNewFolderClicked}
          subFoldderClicked={subFolderClicked}
          folderPath={folderPath}
          folderId={folderId}
          onFlieFolderCreated={onFileUploaded}
          onSearchTermChange={handleSearchTermChange}
        />
        {/* <DataRoom className='mb-5 mb-xl-8' isNewFolder={value} onFolderCreatedOrCancel={updateData} onSubFolderClicked={onSubFolderClicked} folderPath={folderPath} fileFolderCreated = {created}  searchTerm={searchTerm}/> */}
        {/* <VendorRisk className='card-xl-stretch mb-xl-8' /> */}
        <Data_room
          className='mb-5 mb-xl-8'
          isNewFolder={value}
          onFolderCreatedOrCancel={updateData}
          onSubFolderClicked={onSubFolderClicked}
          folderPath={folderPath}
          fileFolderCreated={created}
          searchTerm={searchTerm}
        />
      </KTCard>
    </>
  )
}

export default DataRoomWrapper
