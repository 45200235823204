import React, {useEffect, useRef, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import {addDoc, collection, doc, onSnapshot, serverTimestamp} from 'firebase/firestore'
import db from '../../../../services/firebase'
import {toast} from 'react-toastify'

export interface addAsset {
  msg: string
  message: string
  checked?: boolean | undefined
}
interface AddAssetsProps {
  isOpen: boolean
  onClose: () => void
}

const predefinedOSVersions = [
  // Windows
  'Windows 7',
  'Windows 8',
  'Windows 10',
  'Windows 11',
  'Windows Server 2022',

  // macOS
  'macOS Sierra',
  'macOS High Sierra',
  'macOS Mojave',
  'macOS Catalina',
  'macOS Big Sur',
  'macOS Ventura',

  // Ubuntu
  'Ubuntu 18.04',
  'Ubuntu 20.04',
  'Ubuntu 22.04',
  'Ubuntu 24.04',
  'Ubuntu 23.10',
  'Ubuntu 23.04',

  // Linux Distros
  'Fedora 37',
  'Fedora 38',
  'Fedora 39',
  'Debian 10',
  'Debian 11',
  'Debian 12',

  // Others
  'Chrome OS ',
  'Android 12',
  'Android 13',
  'Android 14',
  'iOS 15',
  'iOS 16',
]

const AddAsset: React.FC<AddAssetsProps> = ({isOpen, onClose}) => {
  const [owner, setOwner] = useState('')
  const [name, setName] = useState('')
  const [serial_number, setSerialNumber] = useState('')
  const [model, setModel] = useState('')
  const [os_version, setOsVersion] = useState('')
  const [last_checkedin, setlast_checkedin] = useState('')
  const [hd_encryption, setHdEncryption] = useState('No')
  const [anti_virus, setAntiVirus] = useState('No')
  const [password_policy, setPasswordPolicy] = useState('No')
  const [screen_policy, setScreenPolicy] = useState('No')
  const [firewall, setFirewall] = useState('No')
  const [audit_scope, setAuditScope] = useState('No')
  const [showAlert, setShowAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const {currentUser} = useAuth()
  const [owners, setOwners] = useState<any[]>([])
  const drawerTopRef = useRef(null)
  const [formErrors, setFormErrors] = useState<Record<string, string>>({})
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [filteredOSVersions, setFilteredOSVersions] = useState<any[]>([])
  const focusRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        if (focusRef.current) {
          focusRef.current.focus()
        }
      }, 300)
    }
  }, [isOpen])

  if (showAlert && drawerTopRef.current) {
    ;(drawerTopRef.current as HTMLElement).scrollIntoView({behavior: 'smooth'})
  }

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      // Logout()
      return
    }
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'employees')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setOwners(risksData)
    })

    return unsubscribe
  }, [])

  const handleInputChange = (e) => {
    const input = e.target.value
    setOsVersion(input)

    if (input.length > 0) {
      const filtered = predefinedOSVersions.filter((version) =>
        version.toLowerCase().includes(input.toLowerCase())
      )
      setFilteredOSVersions(filtered)
      setShowSuggestions(true)
    } else {
      setShowSuggestions(false)
    }
  }

  const handleSuggestionClick = (suggestion) => {
    setOsVersion(suggestion)
    setShowSuggestions(false)
  }

  const validateForm = () => {
    const errors: Record<string, string> = {}

    if (!serial_number.trim()) {
      errors.serialNumber = 'Asset serial number is required'
    }

    if (!owner.trim()) {
      errors.owner = 'Asset owner is required'
    }

    if (!name.trim()) {
      errors.name = 'Asset name is required'
    }
    if (!model.trim()) {
      errors.model = 'Asset model is required'
    }

    if (!os_version.trim()) {
      errors.os_version = 'OS version is required'
    }
    if (!last_checkedin.trim()) {
      errors.last_checkedin = 'Last checkedin date is required'
    }

    // if (!hdEncryption.trim()) {
    //   errors.description = 'Control description is required'
    // }

    // if (!antiVirus.trim()) {
    //   errors.description = 'Control description is required'
    // }
    // if (!passwordPolicy.trim()) {
    //   errors.control = 'Control name is required'
    // }

    // if (!screenPolicy.trim()) {
    //   errors.description = 'Control description is required'
    // }

    // if (!firewall.trim()) {
    //   errors.control = 'Control name is required'
    // }

    // if (!auditScope.trim()) {
    //   errors.description = 'Control description is required'
    // }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleSubmit = async (e) => {
    setIsLoading(true)

    e.preventDefault()

    const newAsset = {
      serial_number,
      owner,
      name,
      model,
      os_version,
      last_checkedin,
      hd_encryption,
      anti_virus,
      password_policy,
      screen_policy,
      firewall,
      audit_scope,
      tenantId: currentUser?.tenantId,
      uid: currentUser?.uid,
    }

    if (!validateForm()) {
      setIsLoading(false)
      return
    }

    if (currentUser) {
      const tenantRef = doc(db, 'tenants', currentUser.tenantId!)

      const policyRef = collection(tenantRef, 'assets')

      const currentDate = new Date()
      const year = currentDate.getFullYear()
      const month = currentDate.getMonth() + 1
      const day = currentDate.getDate()

      const todaysDate = month + '/' + day + '/' + year

      await addDoc(policyRef, newAsset)
      setShowAlert(true)
      toast.success(' New Asset added successfully', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })

      window.scrollTo(0, 0)
      const activitiesRef = collection(tenantRef, 'activities')
      await addDoc(activitiesRef, {
        message: `${currentUser?.userName} added a new asset '${name}'`,
        timestamp: serverTimestamp(),
        tenantId: currentUser?.tenantId,
        uid: currentUser?.uid,
        action: 'Asset added',
        target: 'Asset Management',
        email: currentUser?.email,
        username: currentUser?.userName,
        date: todaysDate,
      })
    }
    setTimeout(() => {
      setShowAlert(false)
      onClose()
    }, 1000)
    setIsLoading(false)
    setName('')
    setOwner('')
    setModel('')
    setOsVersion('')
    setlast_checkedin('')
    setAntiVirus('')
    setHdEncryption('')
    setPasswordPolicy('')
    setScreenPolicy('')
    setFirewall('')
    setAuditScope('')
    setSerialNumber('')
  }

  const toggleState = (currentState, setStateFunction) => {
    setStateFunction(currentState === 'Yes' ? 'No' : 'Yes')
  }

  const onCancel = () => {
    setFormErrors({})
    onClose()
  }

  return (
    <>
      <Drawer open={isOpen} onClose={onCancel} direction='right' className='' size='45%'>
        <div
          className='modal-dialog modal-dialog-centered pt-3 px-6 border-bottom border-gray-300 '
          style={{position: 'sticky', top: 0, backgroundColor: 'white', paddingLeft: '12px'}}
        >
          <div className='modal-content'>
            <div className='modal-header'>
              <h4 className='modal-title mt-2 mb-4'>Add asset</h4>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary '
                onClick={onCancel}
                aria-label='Close'
                style={{
                  transition: 'all 0.2s ease',
                }}
                onMouseEnter={(e) => {
                  if (e.currentTarget) {
                    e.currentTarget.style.backgroundColor = '#fff'
                    const svgElement = e.currentTarget.querySelector('svg')
                    if (svgElement) {
                      svgElement.style.stroke = '#1CBB8D'
                    }
                  }
                }}
                onMouseLeave={(e) => {
                  if (e.currentTarget) {
                    e.currentTarget.style.backgroundColor = '#fff'
                    const svgElement = e.currentTarget.querySelector('svg')
                    if (svgElement) {
                      svgElement.style.stroke = '#5A5A5A'
                    }
                  }
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='#5A5A5A'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  className='icon icon-tabler icons-tabler-outline icon-tabler-x'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <path d='M18 6l-12 12' />
                  <path d='M6 6l12 12' />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div
          className=' scroll-y overflow-auto p-6 mb-4'
          tabIndex={-1}
          style={{height: 'calc(100vh - 30px)'}}
        >
          <form id='kt_risk_form' className='form' onSubmit={handleSubmit}>
            <div className='modal-content'>
              <div className='modal-body'>
                <div className=''>
                  <div className='form-group'>
                    <label className='text-dark fw-bold text-primary my-2 fs-6 required'>
                      Serial number
                    </label>

                    <input
                      type='text'
                      className={`form-control ${formErrors.serialNumber ? 'is-invalid' : ''}`}
                      placeholder='Serial number'
                      name='serialNumber'
                      value={serial_number}
                      onChange={(e) => {
                        setSerialNumber(e.target.value)
                        setFormErrors((prev) => ({...prev, serialNumber: ''}))
                      }}
                      ref={focusRef}
                    />
                    {formErrors.serialNumber && (
                      <span className='text-danger'>{formErrors.serialNumber}</span>
                    )}
                  </div>

                  <div className='form-group'>
                    <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6 required'>
                      Name of Asset
                    </label>

                    <input
                      type='text'
                      className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                      placeholder='Name'
                      name='name'
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value)
                        setFormErrors((prev) => ({...prev, name: ''}))
                      }}
                    />
                    {formErrors.name && <span className='text-danger'>{formErrors.name}</span>}
                  </div>

                  <div className='form-group'>
                    <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6 required'>
                      Model
                    </label>
                    <input
                      type='text'
                      className={`form-control ${formErrors.model ? 'is-invalid' : ''}`}
                      placeholder='Model'
                      value={model}
                      onChange={(e) => {
                        setModel(e.target.value)
                        setFormErrors((prev) => ({...prev, model: ''}))
                      }}
                    />
                    {formErrors.model && <span className='text-danger'>{formErrors.model}</span>}
                  </div>

                  <div className='form-group'>
                    <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6 required'>
                      OS version
                    </label>
                    <input
                      type='text'
                      className={`form-control ${formErrors.os_version ? 'is-invalid' : ''}`}
                      placeholder='OS Version'
                      value={os_version}
                      // onChange={(e) => {
                      //   setOsVersion(e.target.value)
                      //   setFormErrors((prev) => ({...prev, os_version: ''}))
                      // }}
                      onChange={handleInputChange}
                      onBlur={() => setShowSuggestions(false)}
                      onFocus={() => os_version && setShowSuggestions(true)}
                    />
                    {showSuggestions && (
                      <ul
                        className='suggestions-list list-unstyled p-0 m-0 border rounded shadow'
                        style={{
                          position: 'absolute',
                          width: '100%',
                          maxHeight: '200px',
                          overflowY: 'auto',
                          backgroundColor: 'white',
                          zIndex: 1000,
                        }}
                      >
                        {filteredOSVersions.map((suggestion, index) => (
                          <li
                            key={index}
                            onMouseDown={() => handleSuggestionClick(suggestion)}
                            className='suggestion-item px-3 fs-6 py-2 text-hover-primary'
                            style={{
                              cursor: 'pointer',
                              transition: 'background-color 0.2s',
                            }}
                            onMouseEnter={(e) =>
                              (e.currentTarget.style.backgroundColor = '#f1f1f1')
                            }
                            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
                          >
                            {suggestion}
                          </li>
                        ))}
                      </ul>
                    )}

                    {formErrors.os_version && (
                      <span className='text-danger'>{formErrors.os_version}</span>
                    )}
                  </div>
                  <div className='form-group'>
                    <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6 required'>
                      Owner Name
                    </label>

                    <select
                      name='owner'
                      className={`form-select ${formErrors.owner ? 'is-invalid' : ''}`}
                      data-placeholder='Select Owner'
                      value={owner}
                      onChange={(e) => {
                        setOwner(e.target.value)
                        setFormErrors((prev) => ({...prev, owner: ''}))
                      }}
                    >
                      <option value=''>Select Owner</option>
                      {owners
                        .filter((user) => user.role === 'Admin')
                        .map((owner) => (
                          <option key={owner.id} value={owner.full_name}>
                            {owner.full_name}
                          </option>
                        ))}
                    </select>
                    {formErrors.owner && <span className='text-danger'>{formErrors.owner}</span>}
                  </div>
                  <div className='form-group'>
                    <label className='text-dark fw-bold text-primary mt-6 my-2 fs-6 required'>
                      Last checked in
                    </label>

                    <input
                      type='date'
                      className={`form-control ${formErrors.last_checkedin ? 'is-invalid' : ''}`}
                      placeholder='Last checked in'
                      value={last_checkedin}
                      onChange={(e) => {
                        setlast_checkedin(e.target.value)
                        setFormErrors((prev) => ({...prev, last_checkedin: ''}))
                      }}
                    />
                    {/* <Flatpickr
                      value={last_checkedin}
                      onChange={(dates) => {
                        if (dates.length > 0) {
                          setlast_checkedin(dates[0])
                          setFormErrors((prev) => ({...prev, last_checkedin: ''}))
                        }
                      }}
                      className={`form-control ${formErrors.last_checkedin ? 'is-invalid' : ''}`}
                      placeholder='Last checked in'
                    /> */}

                    {formErrors.last_checkedin && (
                      <span className='text-danger'>{formErrors.last_checkedin}</span>
                    )}
                  </div>

                  <div className='form-group w-100 mt-6'>
                    {/* Begin::Heading */}
                    <div className='mb-6'>
                      {/* Begin::Title */}
                      <h2 className='mb-3 fs-6'>Device Details</h2>
                      {/* End::Title */}

                      {/* Begin::Description */}
                      <div className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
                        Select all options that apply
                      </div>
                      {/* End::Description */}
                    </div>
                    {/* End::Heading */}

                    {/* Begin::Input group */}
                    <div
                      className='fv-row mb-15 fv-plugins-icon-container'
                      data-kt-buttons='true'
                      data-kt-initialized='1'
                    >
                      {/* Begin::Option */}
                      {/* <label
                        className={`form-check form-check-custom btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6 mb-6 ${
                          hd_encryption === 'Yes' ? 'active' : ''
                        }`}
                      > */}
                      <label
                        className={`form-check form-check-custom btn btn-outline btn-outline-dashed d-flex text-start p-6 mb-6 ${
                          hd_encryption === 'Yes' ? 'active' : ''
                        }`}
                        style={{
                          backgroundColor: hd_encryption === 'Yes' ? '#E8F8F4' : 'transparent',
                          transition: 'background-color 0.3s',
                        }}
                        onMouseEnter={(e) =>
                          ((e.currentTarget as HTMLElement).style.backgroundColor = '#E8F8F4')
                        }
                        onMouseLeave={(e) =>
                          ((e.currentTarget as HTMLElement).style.backgroundColor =
                            hd_encryption === 'Yes' ? '#E8F8F4' : 'transparent')
                        }
                      >
                        {/* Begin::Checkbox */}
                        <input
                          type='checkbox'
                          checked={hd_encryption === 'Yes'}
                          onChange={() => toggleState(hd_encryption, setHdEncryption)}
                          className='form-check-input me-2 checkbox'
                        />
                        {/* End::Checkbox */}

                        {/* Begin::Label */}
                        <span className='d-flex align-items-center'>
                          {/* Begin::Info */}
                          <span className='ms-4'>
                            <span className='fs-6 fw-bold text-gray-900 mb-2 d-block'>
                              HD Encryption
                            </span>
                            <span className='fw-semibold fs-6 text-gray-700'>
                              The device's hard drive is fully encrypted
                            </span>
                          </span>
                          {/* End::Info */}
                        </span>
                        {/* End::Label */}
                      </label>
                      {/* End::Option */}

                      {/* Begin::Option */}
                      <label
                        className={`form-check form-check-custom mb-6 btn btn-outline btn-outline-dashed d-flex text-start p-6 ${
                          anti_virus === 'Yes' ? 'active' : ''
                        }`}
                        style={{
                          backgroundColor: anti_virus === 'Yes' ? '#E8F8F4' : 'transparent',
                          transition: 'background-color 0.3s',
                        }}
                        onMouseEnter={(e) =>
                          ((e.currentTarget as HTMLElement).style.backgroundColor = '#E8F8F4')
                        }
                        onMouseLeave={(e) =>
                          ((e.currentTarget as HTMLElement).style.backgroundColor =
                            anti_virus === 'Yes' ? '#E8F8F4' : 'transparent')
                        }
                      >
                        {/* Begin::Checkbox */}
                        <input
                          type='checkbox'
                          checked={anti_virus === 'Yes'}
                          onChange={() => toggleState(anti_virus, setAntiVirus)}
                          className='form-check-input me-2'
                        />
                        {/* End::Checkbox */}

                        {/* Begin::Label */}
                        <span className='d-flex align-items-center'>
                          {/* Begin::Info */}
                          <span className='ms-4'>
                            <span className='fs-6 fw-bold text-gray-900 mb-2 d-block'>
                              Antivirus
                            </span>
                            <span className='fw-semibold fs-6 text-gray-700'>
                              The device has up to date antivirus software installed and running
                            </span>
                          </span>
                          {/* End::Info */}
                        </span>
                        {/* End::Label */}
                      </label>
                      {/* End::Option */}

                      {/* Begin::Option */}
                      <label
                        className={`form-check form-check-custom mb-6 btn btn-outline btn-outline-dashed  d-flex text-start p-6 ${
                          password_policy === 'Yes' ? 'active' : ''
                        }`}
                        style={{
                          backgroundColor: password_policy === 'Yes' ? '#E8F8F4' : 'transparent',
                          transition: 'background-color 0.3s',
                        }}
                        onMouseEnter={(e) =>
                          ((e.currentTarget as HTMLElement).style.backgroundColor = '#E8F8F4')
                        }
                        onMouseLeave={(e) =>
                          ((e.currentTarget as HTMLElement).style.backgroundColor =
                            password_policy === 'Yes' ? '#E8F8F4' : 'transparent')
                        }
                      >
                        {/* Begin::Checkbox */}
                        <input
                          type='checkbox'
                          checked={password_policy === 'Yes'}
                          onChange={() => toggleState(password_policy, setPasswordPolicy)}
                          className='form-check-input me-2'
                        />
                        {/* End::Checkbox */}

                        {/* Begin::Label */}
                        <span className='d-flex align-items-center'>
                          {/* Begin::Info */}
                          <span className='ms-4'>
                            <span className='fs-6 fw-bold text-gray-900 mb-2 d-block'>
                              Password Policy
                            </span>
                            <span className='fw-semibold fs-6 text-gray-700'>
                              The device’s password meets company requirements (e.g. length,
                              complexity)
                            </span>
                          </span>
                          {/* End::Info */}
                        </span>
                        {/* End::Label */}
                      </label>
                      {/* End::Option */}

                      {/* Begin::Option */}
                      <label
                        className={`form-check form-check-custom mb-6 btn btn-outline btn-outline-dashed d-flex text-start p-6 ${
                          screen_policy === 'Yes' ? 'active' : ''
                        }`}
                        style={{
                          backgroundColor: screen_policy === 'Yes' ? '#E8F8F4' : 'transparent',
                          transition: 'background-color 0.3s',
                        }}
                        onMouseEnter={(e) =>
                          ((e.currentTarget as HTMLElement).style.backgroundColor = '#E8F8F4')
                        }
                        onMouseLeave={(e) =>
                          ((e.currentTarget as HTMLElement).style.backgroundColor =
                            screen_policy === 'Yes' ? '#E8F8F4' : 'transparent')
                        }
                      >
                        {/* Begin::Checkbox */}
                        <input
                          type='checkbox'
                          checked={screen_policy === 'Yes'}
                          onChange={() => toggleState(screen_policy, setScreenPolicy)}
                          className='form-check-input me-2'
                        />
                        {/* End::Checkbox */}

                        {/* Begin::Label */}
                        <span className='d-flex align-items-center'>
                          {/* Begin::Info */}
                          <span className='ms-4'>
                            <span className='fs-6 fw-bold text-gray-900 mb-2 d-block'>
                              Screen Policy
                            </span>
                            <span className='fw-semibold fs-6 text-gray-700'>
                              The device automatically locks after a short period of inactivity and
                              requires a password/biometric to unlock
                            </span>
                          </span>
                          {/* End::Info */}
                        </span>
                        {/* End::Label */}
                      </label>
                      {/* End::Option */}

                      {/* Begin::Option */}
                      <label
                        className={`form-check form-check-custom mb-6 btn btn-outline btn-outline-dashed d-flex text-start p-6 ${
                          firewall === 'Yes' ? 'active' : ''
                        }`}
                        style={{
                          backgroundColor: firewall === 'Yes' ? '#E8F8F4' : 'transparent',
                          transition: 'background-color 0.3s',
                        }}
                        onMouseEnter={(e) =>
                          ((e.currentTarget as HTMLElement).style.backgroundColor = '#E8F8F4')
                        }
                        onMouseLeave={(e) =>
                          ((e.currentTarget as HTMLElement).style.backgroundColor =
                            firewall === 'Yes' ? '#E8F8F4' : 'transparent')
                        }
                      >
                        {/* Begin::Checkbox */}
                        <input
                          type='checkbox'
                          checked={firewall === 'Yes'}
                          onChange={() => toggleState(firewall, setFirewall)}
                          className='form-check-input me-2'
                        />
                        {/* End::Checkbox */}

                        {/* Begin::Label */}
                        <span className='d-flex align-items-center'>
                          {/* Begin::Info */}
                          <span className='ms-4'>
                            <span className='fs-6 fw-bold text-gray-900 mb-2 d-block'>
                              Firewall
                            </span>
                            <span className='fw-semibold fs-6 text-gray-700'>
                              The device has firewall enabled to protect against unauthorized
                              network access
                            </span>
                          </span>
                          {/* End::Info */}
                        </span>
                        {/* End::Label */}
                      </label>
                      {/* End::Option */}

                      {/* Begin::Option */}
                      <label
                        className={`form-check form-check-custom mb-6 btn btn-outline btn-outline-dashed d-flex text-start p-6 ${
                          audit_scope === 'Yes' ? 'active' : ''
                        }`}
                        style={{
                          backgroundColor: audit_scope === 'Yes' ? '#E8F8F4' : 'transparent',
                          transition: 'background-color 0.3s',
                        }}
                        onMouseEnter={(e) =>
                          ((e.currentTarget as HTMLElement).style.backgroundColor = '#E8F8F4')
                        }
                        onMouseLeave={(e) =>
                          ((e.currentTarget as HTMLElement).style.backgroundColor =
                            audit_scope === 'Yes' ? '#E8F8F4' : 'transparent')
                        }
                      >
                        {/* Begin::Checkbox */}
                        <input
                          type='checkbox'
                          checked={audit_scope === 'Yes'}
                          onChange={() => toggleState(audit_scope, setAuditScope)}
                          className='form-check-input me-2'
                        />
                        {/* End::Checkbox */}

                        {/* Begin::Label */}
                        <span className='d-flex align-items-center'>
                          {/* Begin::Info */}
                          <span className='ms-4'>
                            <span className='fs-6 fw-bold text-gray-900 mb-2 d-block'>
                              Audit Scope
                            </span>
                            <span className='fw-semibold fs-6 text-gray-700'>
                              The device is within your audit scope.
                            </span>
                          </span>
                          {/* End::Info */}
                        </span>
                        {/* End::Label */}
                      </label>
                      {/* End::Option */}

                      <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
                    </div>
                    {/* End::Input group */}
                  </div>

                  {/* <div className='form-group row mt-2'>
                    <div className='col-9 col-form-label'>
                      <div className='checkbox-list'>
                        <label>HD Encryption</label>
                        <label className='form-check form-check-custom form-check-solid p-5'>
                          <input
                            className='form-check-input'
                            type='radio'
                            checked={hdEncryption === 'Yes'}
                            value='Yes'
                            onChange={checkedEncryption}
                            name='hdEncryption'
                          />
                          <span style={{marginLeft: 10, marginRight: 20}}> Yes</span>

                          <input
                            className='form-check-input'
                            type='radio'
                            checked={hdEncryption === 'No'}
                            value='No'
                            onChange={checkedEncryption}
                            name='hdEncryption'
                          />
                          <span style={{marginLeft: 10, marginRight: 20}}>No</span>
                        </label>
                        <label>Antivirus</label>
                        <label className='form-check form-check-custom form-check-solid p-5'>
                          <input
                            className='form-check-input'
                            type='radio'
                            checked={antiVirus === 'Yes'}
                            value='Yes'
                            onChange={checkedAntivirus}
                            name='antiVirus'
                          />
                          <span style={{marginLeft: 10, marginRight: 20}}> Yes</span>

                          <input
                            className='form-check-input'
                            type='radio'
                            checked={antiVirus === 'No'}
                            value='No'
                            onChange={checkedAntivirus}
                            name='antiVirus'
                          />
                          <span style={{marginLeft: 10, marginRight: 20}}>No</span>
                        </label>

                        <label>Password Policy</label>
                        <label className='form-check form-check-custom form-check-solid p-5'>
                          <input
                            className='form-check-input'
                            type='radio'
                            checked={passwordPolicy === 'Yes'}
                            value='Yes'
                            onChange={checkedPasswordPolicy}
                            name='passwordPolicy'
                          />
                          <span style={{marginLeft: 10, marginRight: 20}}> Yes</span>

                          <input
                            className='form-check-input'
                            type='radio'
                            checked={passwordPolicy === 'No'}
                            value='No'
                            onChange={checkedPasswordPolicy}
                            name='passwordPolicy'
                          />
                          <span style={{marginLeft: 10, marginRight: 20}}>No</span>
                        </label>

                        <label>Screen Policy</label>
                        <label className='form-check form-check-custom form-check-solid p-5'>
                          <input
                            className='form-check-input'
                            type='radio'
                            checked={screenPolicy === 'Yes'}
                            value='Yes'
                            onChange={checkedScreenPolicy}
                            name='screenPolicy'
                          />
                          <span style={{marginLeft: 10, marginRight: 20}}> Yes</span>

                          <input
                            className='form-check-input'
                            type='radio'
                            checked={screenPolicy === 'No'}
                            value='No'
                            onChange={checkedScreenPolicy}
                            name='screenPolicy'
                          />
                          <span style={{marginLeft: 10, marginRight: 20}}>No</span>
                        </label>

                        <label>Firewall</label>
                        <label className='form-check form-check-custom form-check-solid p-5'>
                          <input
                            className='form-check-input'
                            type='radio'
                            checked={firewall === 'Yes'}
                            value='Yes'
                            onChange={checkedFirewall}
                            name='firewall'
                          />
                          <span style={{marginLeft: 10, marginRight: 20}}> Yes</span>

                          <input
                            className='form-check-input'
                            type='radio'
                            checked={firewall === 'No'}
                            value='No'
                            onChange={checkedFirewall}
                            name='firewall'
                          />
                          <span style={{marginLeft: 10, marginRight: 20}}>No</span>
                        </label>

                        <label>Audit Scope</label>
                        <label className='form-check form-check-custom form-check-solid p-5'>
                          <input
                            className='form-check-input'
                            type='radio'
                            checked={auditScope === 'Yes'}
                            value='Yes'
                            onChange={checkedAuditScope}
                            name='auditScope'
                          />
                          <span style={{marginLeft: 10, marginRight: 20}}> Yes</span>

                          <input
                            className='form-check-input'
                            type='radio'
                            checked={auditScope === 'No'}
                            value='No'
                            onChange={checkedAuditScope}
                            name='auditScope'
                          />
                          <span style={{marginLeft: 10, marginRight: 20}}>No</span>
                        </label>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div
              className='offcanvas-footer d-flex justify-content-end  border-top py-4'
              style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
            >
              <button
                type='button'
                className='btn btn-sm btn-light fw-bold me-4 px-6 fs-6' // btn-active-light-primary
                style={{height: '40px'}}
                onClick={onCancel}
              >
                Cancel
              </button>
              {isLoading ? (
                <button
                  type='submit'
                  className='btn mb-4 btn-sm fs-6'
                  style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                >
                  {`Add Asset... `}
                  <span className='spinner-border spinner-border-sm align-middle'></span>
                </button>
              ) : (
                <button
                  type='submit'
                  className='btn mb-4 btn-sm fs-6'
                  style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                >
                  Add Asset
                </button>
              )}
            </div>
            {/* </div> */}
          </form>
        </div>
      </Drawer>
    </>
  )
}

export default AddAsset
